<template>
  <div class="title slogan langlist" langname="pay-out" >冲鸭加速为您提供优质的服务</div>
  <div class="flex main">
    <div>
      <div class="main-info-item flex"><img src="@/assets/images/chongya-1.png" alt="解锁全球视频平台高清流媒体解除地域限制"></div>
      <div class="txc title langlist" langname="waiter-title-1">解锁全球平台高清流媒体解除地域限制</div>
      <div class="langlist" langname="waiter-content-1">在世界任何地区,都能流畅观看不同家的NETFLIX, HULU, HBO, Disney.AbemaTV等流媒体内容,并且享受全程高清。</div>
    </div>
    <div>
      <div class="main-info-item flex"><img src="@/assets/images/chongya-2.png" alt="稳定使用部分地区内无法使用的应用和服务"></div>
      <div class="txc title langlist" langname="waiter-title-2">稳定使用部分地区内无法使用的应用</div>
      <div class="langlist" langname="waiter-content-2">使用冲鸭加速可以让您自由使用Twitter,Instagram, Facebook和Telegram等应用每周7x24小时永不掉线,随时和全世界不同地区的朋友进行互动。</div>
    </div>
    <div>
      <div class="main-info-item flex"><img src="@/assets/images/chongya-3.png" alt="无日志，匿名访问应用和网站，保护您的隐私"></div>
      <div class="txc title langlist" langname="waiter-title-3">采用多种传输技术，多个国家线路可选</div>
      <div class="langlist" langname="waiter-content-3">每个国家又部署了不同的服务器，基于全球的服务器网络和独家传输协议，无限带宽，无任何网络节流。</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Offer',
    components: {},
    setup () {

    },
    created () {
    }
  }
</script>

<style scoped>
.txc {
  text-align: center;
}
</style>
