<template>
  <div class="comment-parent">
    <div class="title comment-title langlist" langname="comment">真实的用户评价</div>
    <div class="comment-list">
      <div class="comment-info-pan">
        <div class="comment-info">

        </div>
      </div>
      <div id="buttons" ref="buttons" class="but">
      </div>
      <button class="arrow" id="prev"><span></span></button>
      <button class="arrow" id="next"><span></span></button>
    </div>
  </div>
</template>

<script>
import { localCommentList, setComment } from "@/js/comments"
import {onMounted, ref} from "vue";
  export default {
    name: 'Comment',
    components: {},
    setup(){
      const buttons = ref(null);
      onMounted(() => {
        setComment(buttons.value, localCommentList);
      })
      return {buttons}
    }
  }
</script>

<style scoped>

</style>
