<template>
  <div class="header">
    <div>
      <span>
        <img
          @click="toHome"
          class="icon logo-title"
          src="@/assets/images/ducky_icon.jpg"
          alt="duckyVPN"
        />
      </span>
      <span class="title-text logo-title">
        {{ store.languages[store.lang]["icon-text"] || "冲鸭加速" }}
      </span>
    </div>
    <div class="header-right">
      <CheckLanguage :istyle="'#000000'" :mainstyle="'#565656'"></CheckLanguage>
    </div>
  </div>
</template>

<script>
import CheckLanguage from "./CheckLanguage.vue";
import utils from "../utils/utils";
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "HeadUp",
  components: {
    CheckLanguage,
  },
  setup(props, context) {
    let store = useStore().state;

    let from = ref("index");
    let query = utils.getQuery("from");
    if (query) from.value = query;
    function toHome() {
      if (context.attrs.toHome) context.attrs.toHome();
      else {
        window.location.href = `/app/${from.value}.html`;
      }
    }

    return {
      store,
      toHome,
    };
  },
};
</script>

<style scoped>
.header {
  z-index: 99;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
  border-bottom: 1px solid rgb(32 33 36 / 28%);
}

.header div span {
  cursor: pointer;
}

.header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  font-size: 1.8rem;
}
.icon {
  border-radius: 20%;
  display: block;
  width: 44px;
  height: 44px;
}

.icon img {
  width: 100%;
}

.title-text {
  margin-left: 1rem;
}

.header-right {
  font-size: 1.7rem;
}
</style>
