<template>
  <div class="lang-main" :style="mainstyle">
    <div class="wai">
      <span class="sel" @click.stop="popup"
        >{{ toBeSelectedLanguages[thislang] }}<i :style="i_style"></i
      ></span>
    </div>
    <span class="items" :style="{ display: isPopup ? 'inherit' : 'none' }">
      <span
        class="item"
        v-for="(v, k) in toBeSelectedLanguages"
        :key="k"
        :checkd="thislang === k"
        @click="check(k)"
        >{{ v }}</span
      >
    </span>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import utils from "../utils/utils";

export default {
  name: "CheckLanguage",
  components: {},
  setup(pop, context) {
    let toBeSelectedLanguages = reactive({
      en: "English",
      tw: "繁體中文",
      zh: "简体中文",
    });
    let stores = useStore();
    let store = stores.state;
    let isPopup = ref(false);
    let thislang = ref(store.lang);

    onMounted(() => {
      document.addEventListener("click", () => {
        shrink();
      });
    });

    function popup() {
      isPopup.value = true;
    }

    function check(currentLang) {
      if (context.attrs.change) context.attrs.change(currentLang);
      else {
        thislang.value = currentLang;
        stores.commit("change", currentLang);
        isPopup.value = false;
        document.title = store.languages[store.lang]["title"];
      }
      utils.setCookie("lang", currentLang);
    }

    function shrink() {
      isPopup.value = false;
    }
    let icolor = context.attrs.istyle || "#ffffff";
    let i_style = {
      border: "6px solid " + icolor,
      "border-left-color": "transparent",
      "border-bottom-color": "transparent",
      "border-right-color": "transparent",
    };
    let mainColor = context.attrs.mainstyle || "#ffffff";
    let mainstyle = {
      color: mainColor,
    };
    return {
      thislang,
      isPopup,
      toBeSelectedLanguages,
      popup,
      check,
      shrink,
      i_style,
      mainstyle,
    };
  },
  created() {},
};
</script>

<style scoped>
.lang-main {
  background-color: revert;
  position: relative;
  padding: 0 !important;
}
.wai {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
}

.sel {
  white-space: nowrap;
  margin-right: 1rem;
}

.item {
  padding-right: 0.3rem;
  display: inline-block;
}

i {
  position: relative;
  top: 0.65rem;
  right: -0.3rem;
  vertical-align: middle;
}

.items {
  display: flex;
  position: absolute;
  top: 1.5rem;
  right: 0.8rem;
  color: rgb(86, 86, 86);
  background-color: rgba(245, 243, 243, 0.9);
  line-height: 1.8rem;
  width: 5.8rem;
  font-weight: 400;
  padding-left: 0.6rem;
  padding-right: 0.2rem;
  flex-direction: column;
  cursor: pointer;
  font-size: 1rem;
}

.item[checkd="true"]::after {
  content: "";
  display: inline-block;
  margin-left: 0.48rem;
  margin-bottom: 4px;
  top: 50%;
  right: 0.48rem;
  width: 0.776667rem;
  height: 0.336667rem;
  border-left: 1px solid #070707;
  border-bottom: 1px solid #030303;
  transform: translateY(-2px) rotateZ(-45deg);
}
</style>
