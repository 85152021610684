<template>
  <div class="footer">
    <div class="footer-top title">
      {{ store.languages[store.lang]["about"] || "关于我们" }}
    </div>
    <hr />
    <div class="footer-text footer-small">
      <div class="footer-center flex">
        <div class="flex">
          <div class="flex">
            <p class="title">
              {{ store.languages[store.lang]["about-us"] || "关于我们" }}
            </p>
            <p>
              <a class="service" :href="`dky_terms_of_service.html?from=${from}`">{{
                store.languages[store.lang]["terms-service"] || "服务条款"
              }}</a>
            </p>
            <p>
              <a class="privacy" :href="`dky_privacy_policy.html?from=${from}`">{{
                store.languages[store.lang]["privacy-policy"] || "隐私政策"
              }}</a>
            </p>
          </div>
        </div>
        <div class="flex footer-img">
          <a href="https://twitter.com/DuckyVpn" target="_blank" class="flex">
            <img src="@/assets/images/twitter.png" alt="ducky-twitter" />
            <span>Twitter</span>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100070230468463"
            target="_blank"
            class="flex"
          >
            <img src="@/assets/images/facebook.png" alt="ducky-facebook" />
            <span>Facebook</span>
          </a>
          <a
            href="https://www.instagram.com/duckyvpn/"
            target="_blank"
            class="flex"
          >
            <img src="@/assets/images/instagram.png" alt="ducky-instagram" />
            <span>Instagram</span>
          </a>
        </div>
      </div>

      <div class="footer-center flex">
        <div class="footer-text flex">
          <p class="title">
            {{ store.languages[store.lang]["partners"] || "合作" }}
          </p>
          <p><a href="mailto:duckybd@outlook.com">duckybd@outlook.com</a></p>
        </div>
        <div class="footer-text flex">
          <p class="title">
            {{ store.languages[store.lang]["help-center"] || "客服中心" }}
          </p>
          <p>
            <a
              :href="`mailto:${contactEmail}?subject=${contactSubject}&body=${contactBody}`"
              class="help-mail"
              >{{ contactEmail || "dkyhelp@outlook.com" }}</a
            >
          </p>
          <p class="tawkstar" @click="toggle">
            {{ store.languages[store.lang]["online-service"] || "在线客服" }}
          </p>
          <p><a target="_blank" :href="contactTelegram">Telegram</a></p>
          <p><a :href="contactWhatsApp" target="_blank">WhatsApp</a></p>
          <p><a :href="contactSkype" target="_blank">Skype</a></p>
          <p><a :href="contactPotato" target="_blank">Potato</a></p>
        </div>
      </div>
    </div>
    <div class="footer-text footer-center flex footer-bigger">
      <div class="footer-text flex">
        <p class="title">
          {{ store.languages[store.lang]["about-us"] || "关于我们" }}
        </p>
        <p>
          <a class="service" :href="`dky_terms_of_service.html?from=${from}`">{{
            store.languages[store.lang]["terms-service"] || "服务条款"
          }}</a>
        </p>
        <p>
          <a class="privacy" :href="`dky_privacy_policy.html?from=${from}`">{{
            store.languages[store.lang]["privacy-policy"] || "隐私政策"
          }}</a>
        </p>
      </div>
      <div class="footer-text flex">
        <p class="title">
          {{ store.languages[store.lang]["partners"] || "合作" }}
        </p>
        <p><a href="mailto:duckybd@outlook.com">duckybd@outlook.com</a></p>
      </div>
      <div class="flex footer-img">
        <a href="https://twitter.com/DuckyVpn" target="_blank" class="flex">
          <img src="@/assets/images/twitter.png" alt="ducky-twitter" />
          <span>Twitter</span>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100070230468463"
          target="_blank"
          class="flex"
        >
          <img src="@/assets/images/facebook.png" alt="ducky-facebook" />
          <span>Facebook</span>
        </a>
        <a
          href="https://www.instagram.com/duckyvpn/"
          target="_blank"
          class="flex"
        >
          <img src="@/assets/images/instagram.png" alt="ducky-instagram" />
          <span>Instagram</span>
        </a>
      </div>
      <div class="footer-text flex">
        <p class="title">
          {{ store.languages[store.lang]["help-center"] || "客服中心" }}
        </p>
        <p>
          <a
            :href="`mailto:${contactEmail}?subject=${contactSubject}&body=${contactBody}`"
            class="help-mail"
            >{{ contactEmail || "dkyhelp@outlook.com" }}</a
          >
        </p>
        <p class="tawkstar" @click="toggle">
          {{ store.languages[store.lang]["online-service"] || "在线客服" }}
        </p>
        <p><a target="_blank" :href="contactTelegram">Telegram</a></p>
        <p><a :href="contactWhatsApp" target="_blank">WhatsApp</a></p>
        <p><a :href="contactSkype" target="_blank">Skype</a></p>
        <p><a :href="contactPotato" target="_blank">Potato</a></p>
      </div>
    </div>
    <hr />
    <div class="flex footer-bottom footer-text">
      <p>{{'© ' + new Date().getUTCFullYear() }} {{ store.languages[store.lang]["product"] ||"冲鸭加速" }}</p>
<!--      <p class="footer-bottom-addr">VAPORWAVE PTE. LTD. All Rights Reserved.</p>-->
<!--      <p class="footer-bottom-addr">Singapore 048581</p>-->
<!--      <p class="footer-bottom-addr-all">-->
<!--        VAPORWAVE PTE. LTD. All Rights Reserved.-->
<!--      </p>-->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import utils from "../utils/utils";

export default {
  name: "Footer",
  components: {},
  setup() {
    let useStores = useStore();
    let store = useStores.state;

    function toggle() {
      let widgetOpen = window.sessionStorage.getItem("ZD-widgetOpen");
      try {
        if (widgetOpen && widgetOpen === "true") {
          window.zE("messenger", "close");
        } else {
          window.zE("messenger", "open");
        }
      } catch (e) {
        console.log("toggle fail");
      }
    }

    let contactSubject = ref("");
    let contactBody = ref("");
    let contactEmail = ref("dkyhelp@outlook.com");

    let contactTwitter = ref("https://twitter.com/DuckyVpn");
    let contactFacebook = ref(
      "https://www.facebook.com/profile.php?id=100070230468463"
    );
    let contactInstagram = ref("https://www.instagram.com/duckyvpn/");

    let contactSkype = ref("https://join.skype.com/invite/wPZt1qblg4pJ");
    let contactWhatsApp = ref("https://wa.me/qr/UIQRFMOTYP72E1");
    let contactTelegram = ref("https://t.me/duckyvpn0");
    let contactPotato = ref("https://ptcc.in/chongyajiasu");

    let from = ref(utils.getQuery("from") || "index");

    if (window.env && window.env.channel) from.value = window.env.channel;
    if (window.location.href.includes("index")) from.value = "index";


    return {
      from,
      store,
      toggle,
      contactSubject,
      contactBody,
      contactEmail,
      contactTwitter,
      contactFacebook,
      contactInstagram,
      contactSkype,
      contactWhatsApp,
      contactTelegram,
      contactPotato,
    };
  },
  created() {
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
a,
a:link,
a:visited {
  text-decoration: none;
  color: inherit;
}
.flex {
  display: flex;
  display: -webkit-flex;
}

.title {
  color: #e0812c;
  font-weight: 900;
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}

.footer {
  background-color: #ffffff;
  padding: 0 10% 1.4rem 10%;
}

.footer-text {
  color: #7f7f7f;
  font-weight: 500;
}

.footer-top {
  text-align: center;
  margin-bottom: 1.16rem;
}

.footer-center p {
  display: inline-block;
  line-height: 1.9rem;
}

.footer-center > div {
  flex-direction: column;
}

.footer-center {
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer a[class="flex"] {
  margin-bottom: 0.5rem;
  align-items: center;
}

.footer a span {
  margin-left: 0.7rem;
  height: 1rem;
}

.footer img {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
}

.footer-bottom {
  line-height: 2rem;
  font-size: 0.88rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
}

.footer-small,
.footer-bigger {
  margin: 1.5rem 0;
}

.footer-small {
  display: none;
  justify-content: space-between;
}

.footer-small > div {
  flex-direction: column;
  justify-content: flex-start;
}

.footer-small > div:last-child > div:first-child {
  margin-bottom: 3.3rem;
}

.footer-small .footer-img,
.footer-bigger .footer-img {
  margin-top: 0.4rem;
}

.footer-small > div > div > div:first-child {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.tawkstar,
.service,
.privacy {
  cursor: pointer;
}

@media all and (max-width: 419.999px) and (min-width: 150px) {
  .footer-bigger {
    display: none;
  }

  .footer-small {
    display: flex;
  }

  .footer-bottom-addr-all {
    display: none;
  }

  .footer-text {
    font-size: 1rem;
  }
}

@media all and (max-width: 499.9999px) and (min-width: 420px) {
  .footer-bigger {
    display: none;
  }

  .footer-small {
    display: flex;
  }

  .footer-bottom-addr-all {
    display: none;
  }

  .footer-text {
    font-size: 0.8rem;
  }
}

@media all and (max-width: 799.9999px) and (min-width: 500px) {
  .footer-bigger {
    display: none;
  }

  .footer-small {
    display: flex;
  }

  .footer-bottom-addr-all {
    display: none;
  }

  .footer-text {
    font-size: 1rem;
  }
}

@media all and (max-width: 1399.9999px) and (min-width: 800px) {
  .footer-small {
    display: none;
  }

  .footer-bigger {
    display: flex;
  }

  .footer-bottom-addr {
    display: none;
  }
}

@media all and (max-width: 1599.9999px) and (min-width: 1400px) {
  .footer-small {
    display: none;
  }

  .footer-bigger {
    display: flex;
  }

  .footer-bottom-addr {
    display: none;
  }
}

@media all and (max-width: 2099.9999px) and (min-width: 1600px) {
  .footer-small {
    display: none;
  }

  .footer-bigger {
    display: flex;
  }

  .footer-bottom-addr {
    display: none;
  }
}

@media all and (max-width: 3000px) and (min-width: 2100px) {
  .footer-small {
    display: none;
  }

  .footer-bigger {
    display: flex;
  }

  .footer-bottom-addr {
    display: none;
  }
}
</style>
