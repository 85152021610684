const languages = {
  en: {
    title: "DuckyVPN",
    "icon-text": "DuckyVPN",
    "download-android": "Android Download",
    "download-ios": "iOS Download",
    "pay-out": "Fast, stable and safe<span>Rush !</span>",
    "waiter-title-1": " High Definition",
    "waiter-content-1":
      "Smoothly watch Youtube,<br/>Netflix, HBO, Disney, etc.<br/>streaming media content, full HD.",
    "waiter-title-2": "7x24 Not Dropped",
    "waiter-content-2":
      "Freely use Instagram,<br/>Twitter, Facebook, Telegram, etc.<br/>7x24 hours without disconnection.",
    "waiter-title-3": "No Log",
    "waiter-content-3":
      "\n" +
      "No log record,<br/>Your visit record, only you know,<br/>Protect your privacy to the greatest extent.",
    comment: "Real user reviews",
    about: "About",
    "about-us": "About",
    "terms-service": "Terms of Service ",
    "privacy-policy": "Privacy Policy ",
    partners: "Partners",
    "help-center": "Help",
    "online-service": "Contact Us",
    product: `DuckyVPN`,
  },
  zh: {
    title: "冲鸭加速",
    "icon-text": "冲鸭加速",
    "download-android": "最新版本下载",
    "download-ios": "iOS 下载",
    "pay-out": "又快又稳还安全<span>冲鸭 !</span>",
    "waiter-title-1": " HD 高清",
    "waiter-content-1":
      "流畅观看Youtube,<br/> Netflix, HBO, Disney 等<br/>流媒体内容, 全程高清。",
    "waiter-title-2": "7x24 不掉线",
    "waiter-content-2":
      "自由使用 Instagram,<br/> Twitter, Facebook, Telegram 等<br/>7x24 小时不掉线。",
    "waiter-title-3": "无日志",
    "waiter-content-3":
      "无日志记录,<br/>您的访问记录, 只有您知道,<br/>最大程度保护您的隐私。",
    comment: "真实的用户评价",

    about: "关于我们",
    "about-us": "关于我们",
    "terms-service": "服务条款",
    "privacy-policy": "隐私政策",
    partners: "合作",
    "help-center": "客服中心",
    "online-service": "在线客服",
    product: "冲鸭加速",
  },
  tw: {
    title: "冲鴨加速",
    "icon-text": "冲鴨加速",
    "download-android": "最新版本下载",
    "download-ios": "iOS 下載",
    "pay-out": "又快又穩還安全<span>冲鴨 !</span>",
    "waiter-title-1": "HD 高清",
    "waiter-content-1":
      "流暢觀看Youtube,<br/> Netflix, HBO, Disney 等<br/>流媒體內容, 全程高清。",
    "waiter-title-2": "7x24 不掉線",
    "waiter-content-2":
      "自由使用 Instagram,<br/> Twitter, Facebook, Telegram 等<br/>7x24 小時不掉線。",
    "waiter-title-3": "無日誌",
    "waiter-content-3":
      "無日誌記錄,<br/>您的訪問記錄, 只有您知道,<br/>最大程度保護您的隱私。",
    comment: "真實的用戶評價",

    about: "關于我們",
    "about-us": "關于我們",
    "terms-service": "服務條款",
    "privacy-policy": "隱私政策",
    partners: "合作",
    "help-center": "客服中心",
    "online-service": "在綫客服",
    product: "冲鴨加速",
  },
};

export default languages;
