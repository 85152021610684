let privacyPolicyLanguages = {
  en: {
    policy0: "DuckyVpn Privacy Policy ",
    policy1: "DuckyVpn servers do NOT and WILL NEVER log:",
    policy2:
      "Linking DuckyVpn account to real identity (such as purchase information)",
    policy3:
      "Connection logs (time stamps of connection of IP addresses from client to DuckyVpn server)",
    policy4: "Browsing history",
    policy5: "Traffic destination or metadata",
    policy6: "DNS queries",
    policy7:
      "DuckyVpn is committed to protecting your privacy. We want you to understand what information. we collect, what we don’t collect, and how we collect, use, and store information. We do not collect logs of your activity, including no logging of browsing history, traffic destination, data content, or DNS queries. We also never store connection logs, meaning no logs of your IP address, your outgoing DuckyVpn server IP address associated with the connection timestamp, or session duration. ",
    policy8:
      "Our guiding principle toward data collection is to collect only the minimal data required to. operate a world-class service at scale. We designed our systems to not have sensitive data. about our customers; even when compelled, we cannot provide data that we do not possess.",
    policy9:
      "This privacy policy will help you understand how DuckyVpn collects, uses, and stores information.",
    policy10: "General Information ",
    policy11: "DuckyVpn collects four types of information: ",
    policy12: "Information related to your account (“personal information”) ",
    policy13:
      "This information is collected for the purpose of administering your DuckyVpn  subscription and includes your username, email address, and payment information, which you submit on our order page when you subscribe for the Services. We accept any email address because the email address is not verified. All Payment information such as transaction ID, payment account. will be disassociated after account creation.",
    policy14:
      "Aggregate Apps and DuckyVpn server connection summary statistics",
    policy15:
      "DuckyVpn collects minimal information about usage in order to maintain excellent customer. support and quality of service. The section below specifies in detail what information we collect. These statistics never include anything about what the user did with the DuckyVpn  server: no data about the contents or destinations of DuckyVpn  server traffic, no DNS queries, and no IP connection logs.",
    policy16:
      "(User-controlled option): Anonymous app diagnostics, including crash Reports",
    policy17:
      "App diagnostic data, which include crash reports, usability diagnostics, and DuckyVpn  server. connection diagnostics, are anonymized and cannot be tied back to individual DuckyVpn  users.",
    policy18: "Personal Information ",
    policy19:
      "DuckyVpn  collects personal information that you provide to us directly through the app. The specific information collected varies depending on the payment method you choose. However, all Payment information such as transaction ID, payment account will be disassociated after. account creation",
    policy20:
      "We accept any email address and the email address is not verified.",
    policy21:
      "DuckyVpn  uses the email address you provided for the following reasons, :",
    policy22: "To send emails related to payment transactions.",
    policy23: "To provide links to our Site.",
    policy24: "To send you updates and announcements.",
    policy25:
      "To communicate with you about your DuckyVpn  server services or respond to your, communications.",
    policy26: "To send marketing information",
    policy27:
      "DuckyVpn  uses your personal information only for the purposes listed in this Privacy Policy, and we do not sell your personal information to third parties.",
    policy28:
      "Aggregate Apps and DuckyVpn  server Connection Summary Statistics ",
    policy29:
      "We ensure that we never log browsing history, traffic destination, data content, IP addresses, or DNS queries. Therefore:",
    policy30:
      "We do not know which user ever accessed a particular website or service.",
    policy31:
      "We do not know which user was connected to the DuckyVpn  server at a specific time or which DuckyVpn  server IP addresses they used at a specific time.",
    policy32:
      "Should anyone try to compel DuckyVpn  to release user information based on any of the above, we cannot supply this information because the data don’t exist.",
    policy33:
      "In order to maintain excellent customer support and quality of service, DuckyVpn  collects the following information related to your DuckyVpn  server usage:",
    policy34: "Device ID",
    policy35:
      "Device ID a randomly generated identifier that is stored on the user's machine. It cannot be tied to the user’s hardware such as hardware serial number, MAC address, IMEI. We collect this information for diagnostic purposes and allow you to manage your account.",
    policy36: "Apps and Apps versions",
    policy37:
      "We collect information related to which Apps and Apps version(s) you have activated. Knowing your current version of the Apps allows our Support Team to troubleshoot technical issues with you.",
    policy38: "Language/local setting",
    policy39:
      "We collect information on your operating system language and local setting to help customize",
    policy40:
      "your DuckyVpn interface Aggregate sum of data transferred (in MB)",
    policy41:
      "We collect information regarding the total sum of data transferred by a given user and a given device. Although we provide unlimited data transfer for pro users, if we notice that a single user or device pushes more traffic than thousands of others combined, thereby affecting the quality of service for other DuckyVpn  users, we may contact that user for an explanation.",
    policy42: "Summary",
    policy43:
      "We collect minimal usage statistics to maintain our quality of service. We may know, for example, that a DuckyVpn  account has access to our DuckyVpn  server in New York, Hong Kong and Japan, and consumed 5GB of data for the month. The DuckyVpn  account can’t be uniquely identified as responsible for any specific behavior because his usage pattern overlaps with thousands of other DuckyVpn  customers who also have access to the same DuckyVpn  servers . ",
    policy44:
      "We’ve engineered our systems to categorically eliminate storage of sensitive data. We never know WHO the account holder is or HOW they have utilized our Service. We stand by our firm commitment to our customers’ privacy by not possessing any data related to a user’s online activities.",
    policy45:
      "Anonymous App Diagnostics, including Crash Reports(can be turned off by the user)",
    policy46:
      "With your permission, we collect anonymized app diagnostic data, which include crash reports, usability diagnostics, and DuckyVpn  server connection diagnostics. We use these data in our   network operations tools to help optimize network speeds and to let us identify problems and    areas for improvement related to specific apps, DuckyVpn  servers, or ISPs. The information we  receive is fully anonymized and cannot be tied back to individual DuckyVpn  users (i.e., we do   not store which user sent which data, and we do not store user IP addresses).",
    policy47: "Consent and Age Restrictions",
    policy48:
      "By using the Site, Content, Apps, Software, or Services, you agree to have your information handled as described in our Terms of Service and Privacy Policy. ",
    policy49:
      "The Services are intended for adults aged 18 and above. If you believe your child has provided information to us, please let us know immediately.",
    policy50: "Changes to the Privacy Policy",
    policy51:
      "We may change our Privacy Policy from time to time, without prior notice to you, consistent with applicable privacy laws and principles. Your continued use of the Site or Services constitutes, your acceptance of our Privacy Policy.",
    policy52: "How to Contact DuckyVpn ",
    policy53:
      "If you have any questions regarding our Privacy Policy and how we handle your information, please feel free to contact DuckyVpn  at the following email address: dkyhelp@outlook.com",
  },
  zh: {
    policy0: "冲鸭加速隐私政策",
    policy1: "冲鸭加速服务器现在不会以后也不会记录：",
    policy2: "将冲鸭加速账号与实名信息关联（比如购买信息）",
    policy3: "连接日志（从客户端到冲鸭加速服务器的IP地址连接的时间戳）",
    policy4: "浏览历史",
    policy5: "流量目的地和元数据",
    policy6: "DNS查询",
    policy7:
      "冲鸭加速致力于保护您的隐私。我们希望您了解我们收集的信息，不收集的信息以及我们如何收集，使用和存储信息。我们不收集您的活动日志，包括不记录浏览历史记录，流量目的地，数据内容或DNS查询。我们也从不存储连接日志，这意味着我们不记录您的IP地址，与连接时间戳或会话持续时间相关的冲鸭加速服务器的IP地址。",
    policy8:
      "我们对数据收集的指导原则是收集最少数据以提供规模化的世界领先的服务。我们设计的系统没有关于客户的敏感数据；即使被迫要求提供，我们也无法提供我们不拥有的数据。",
    policy9: "本隐私政策将帮助您了解冲鸭加速如何收集，使用和存储信息。",
    policy10: "基本信息",
    policy11: "收集以下四类信息",
    policy12: "与你账号相关的信息（“个人信息”）",
    policy13:
      "冲鸭加速收集这些信息是为了管理您的订阅信息，其中包括您的用户名，电子邮件地址和付款信息，这些信息是您在订阅服务时在我们的订单页面上提交的。你能输入任何email地址，我们并不会确认email地址。 所有的付款信息，比如付款账号和订单编号在账号生成后会于账号解除绑定。",
    policy14: "汇总应用程序和冲鸭加速服务器连接统计信息",
    policy15:
      "冲鸭加速仅收集与使用情况相关的最少信息，以维持良好的客户支持和服务质量。以下部分详细说明了我们收集的信息。这些统计信息永远不会包含有关用户使用冲鸭加速服务器所做的任何事情：没有冲鸭加速服务器流量的内容或目的地的数据，没有DNS查询，也没有IP连接日志。",
    policy16: "（用户控制的选项）：匿名应用诊断，包括崩溃报告",
    policy17:
      "应用程序诊断数据（包括崩溃报告，可用性诊断和冲鸭加速服务器连接诊断）是匿名的，无法反向绑定到个体冲鸭加速用户。",
    policy18: "个人信息",
    policy19:
      "冲鸭加速会通过应用向您收集您直接提供给我们的个人信息。收集的具体信息取决于您选择的付款方式。但是所有的付款信息，比如付款账号和订单编号在账号生成后会于账号解除绑定。",
    policy20: "你能输入任何email地址，我们并不会确认email地址。",
    policy21: "冲鸭加速使用您的电子邮件地址的原因如下：",
    policy22: "发送与付款交易有关的电子邮件。",
    policy23: "提供指向我们网站的链接。",
    policy24: "向您发送更新和公告。",
    policy25: "与您交流有关冲鸭加速服务器服务的信息或回复您的通信。",
    policy26: "发送营销信息。",
    policy27:
      "冲鸭加速仅将您的个人信息用于本隐私政策中列出的目的，我们不会将您的个人信息出售给第三方。",
    policy28: "汇总应用程序和冲鸭加速服务器连接统计信息",
    policy29:
      "我们确保永远不会记录浏览历史，流量目的地，数据内容，IP地址或DNS查询。因此：",
    policy30: "我们不知道具体某个用户曾经访问过特定的网站或服务。",
    policy31:
      "我们不知道具体某个用户在特定时间连接到冲鸭加速服务器，或者他们在特定时间使用哪个冲鸭加速服务IP地址。",
    policy32:
      "如果冲鸭加速被强迫基于上述任何一种方式来提供用户信息，我们将无法提供此信息，因为数据不存在。",
    policy33:
      "为了维持良好的客户支持和服务质量，冲鸭加速收集与您的冲鸭加速服务器使用情况有关的以下信息：",
    policy34: "设备识别码",
    policy35:
      "设备识别码是随机生成并储存在用户的本地. 设备识别码和硬件序列号，MAC地址，IMEI等无任何关联。我们收集设备识别码来进行故障分析和帮助您管理账号。",
    policy36: "应用和应用版本",
    policy37:
      "我们收集与您激活的应用程序和应用程序版本相关的信息。了解您当前的应用版本，我们的支持团队就可以对您的技术问题进行故障排除。",
    policy38: "语言/本地设置",
    policy39:
      "我们收集有关您的操作系统语言和本地设置的信息，以帮助自定义您的冲鸭加速界面。",
    policy40: "传输的数据总和（以MB为单位）",
    policy41:
      "我们收集有关给定用户传输的数据总和的信息。尽管我们为专业用户提供了不限流量的数据传输，但是如果我们发现一个用户比其他数千个用户的总流量更多，从而影响了其他冲鸭加速用户的服务质量，我们可能联系该用户以获取解释。",
    policy42: "摘要",
    policy43:
      "我们收集最少的使用情况统计信息以维持我们的服务质量。例如，我们可能知道我们的某个冲鸭加速账号可以访问我们在纽约，香港和日本的冲鸭加速服务器，并且当月消耗了5GB的数据。由于这个冲鸭加速账号的使用方式与数千名也可以访问相同冲鸭加速服务器的冲鸭加速客户重叠，因此无法将此冲鸭加速账号唯一识别为对任何特定行为负责。",
    policy44:
      "我们对系统进行了设计以明确消除敏感数据的存储。我们不知道此冲鸭加速账号的使用者的实际身份，也不知道他们如何使用我们的服务。我们不拥有任何与用户在线活动有关的数据，从而坚定了对客户隐私的坚定承诺。",
    policy45: "匿名的应用程序诊断，包括崩溃报告（可由用户关闭）",
    policy46:
      "经过您的许可，我们会收集匿名的应用程序诊断数据，其中包括崩溃报告，可用性诊断和冲鸭加速服务器连接诊断。我们在网络操作工具中使用这些数据来帮助优化网络速度，并帮助我们确定与特定应用程序，冲鸭加速服务器或ISP相关的问题和需要改进的地方。我们收到的信息是完全匿名的，不能绑定到各个冲鸭加速用户（即，我们不存储哪个用户发送了哪些数据，也不存储用户IP地址）。",
    policy47: "同意和年龄限制",
    policy48:
      "使用网站，内容，应用程序，软件或服务，即表示您同意按照我们的服务条款和隐私权政策中所述处理您的信息。",
    policy49:
      "该服务适用于18岁以上的成年人。如果您认为您的孩子已经向我们提供了信息，请立即告知我们。",
    policy50: "隐私政策的变更",
    policy51:
      "根据适用的隐私法律和原则，我们可能会不时更改隐私政策，恕不另行通知。您继续使用本网站或服务即表示您接受我们的隐私政策。",
    policy52: "如何联系冲鸭加速",
    policy53:
      "如果您对我们的隐私政策以及我们如何处理您的信息有任何疑问，请随时通过以下电子邮件地址与冲鸭加速联系：dkyhelp@outlook.com",
  },
  tw: {
    policy0: "冲鴨加速隱私政策",
    policy1: "冲鴨加速服務器現在不會以後也不會記錄：",
    policy2: "將冲鴨加速賬號與實名信息關聯（比如購買信息）",
    policy3: "連接日誌（從客戶端到冲鴨加速服務器的IP地址連接的時間戳）",
    policy4: "瀏覽歷史",
    policy5: "流量目的地和元數據",
    policy6: "DNS查詢",
    policy7:
      "冲鴨加速致力於保護您的隱私。我們希望您了解我們收集的信息，不收集的信息以及我們如何收集，使用和存儲信息。我們不收集您的活動日誌，包括不記錄瀏覽歷史記錄，流量目的地，數據內容或DNS查詢。我們也從不存儲連接日誌，這意味著我們不記錄您的IP地址，與連接時間戳或會話持續時間相關的冲鴨加速服務器的IP地址。",
    policy8:
      "我們對數據收集的指導原則是收集最少數據以提供規模化的世界領先的服務。我們設計的系統沒有關於客戶的敏感數據；即使被迫要求提供，我們也無法提供我們不擁有的數據。",
    policy9: "本隱私政策將幫助您了解冲鴨加速如何收集，使用和存儲信息。",
    policy10: "基本信息",
    policy11: "收集以下四類信息",
    policy12: "與你賬號相關的信息（“個人信息”）",
    policy13:
      "冲鴨加速收集這些信息是為了管理您的訂閱信息，其中包括您的用戶名，電子郵件地址和付款信息，這些信息是您在訂閱服務時在我們的訂單頁面上提交的。你能輸入任何email地址，我們並不會確認email地址。所有的付款信息，比如付款賬號和訂單編號在賬號生成後會於賬號解除綁定。",
    policy14: "匯總應用程序和冲鴨加速服務器連接統計信息",
    policy15:
      "冲鴨加速僅收集與使用情況相關的最少信息，以維持良好的客戶支持和服務質量。以下部分詳細說明了我們收集的信息。這些統計信息永遠不會包含有關用戶使用冲鴨加速服務器所做的任何事情：沒有冲鴨加速服務器流量的內容或目的地的數據，沒有DNS查詢，也沒有IP連接日誌。",
    policy16: "（用戶控制的選項）：匿名應用診斷，包括崩潰報告",
    policy17:
      "應用程序診斷數據（包括崩潰報告，可用性診斷和冲鴨加速服務器連接診斷）是匿名的，無法反向綁定到個體冲鴨加速用戶。",
    policy18: "個人信息",
    policy19:
      "冲鴨加速會通過應用向您收集您直接提供給我們的個人信息。收集的具體信息取決於您選擇的付款方式。但是所有的付款信息，比如付款賬號和訂單編號在賬號生成後會於賬號解除綁定。",
    policy20: "你能輸入任何email地址，我們並不會確認email地址。",
    policy21: "冲鴨加速使用您的電子郵件地址的原因如下：",
    policy22: "發送與付款交易有關的電子郵件。",
    policy23: "提供指向我們網站的鏈接。",
    policy24: "向您發送更新和公告。",
    policy25: "與您交流有關冲鴨加速服務器服務的信息或回复您的通信。",
    policy26: "發送營銷信息。",
    policy27:
      "冲鴨加速僅將您的個人信息用於本隱私政策中列出的目的，我們不會將您的個人信息出售給第三方。",
    policy28: "匯總應用程序和冲鴨加速服務器連接統計信息",
    policy29:
      "我們確保永遠不會記錄瀏覽歷史，流量目的地，數據內容，IP地址或DNS查詢。因此：",
    policy30: "我們不知道具體某個用戶曾經訪問過特定的網站或服務。",
    policy31:
      "我們不知道具體某個用戶在特定時間連接到冲鴨加速服務器，或者他們在特定時間使用哪個冲鴨加速服務IP地址。",
    policy32:
      "如果冲鴨加速被強迫基於上述任何一種方式來提供用戶信息，我們將無法提供此信息，因為數據不存在。",
    policy33:
      "為了維持良好的客戶支持和服務質量，冲鴨加速收集與您的冲鴨加速服務器使用情況有關的以下信息：",
    policy34: "設備識別碼",
    policy35:
      "設備識別碼是隨機生成並儲存在用戶的本地. 設備識別碼和硬件序列號，MAC地址，IMEI等無任何關聯。我們收集設備識別碼來進行故障分析和幫助您管理賬號。",
    policy36: "應用和應用版本",
    policy37:
      "我們收集與您激活的應用程序和應用程序版本相關的信息。了解您當前的應用版本，我們的支持團隊就可以對您的技術問題進行故障排除。",
    policy38: "語言/本地設置",
    policy39:
      "我們收集有關您的操作系統語言和本地設置的信息，以幫助自定義您的冲鴨加速界面。",
    policy40: "傳輸的數據總和（以MB為單位）",
    policy41:
      "我們收集有關給定用戶傳輸的數據總和的信息。儘管我們為專業用戶提供了不限流量的數據傳輸，但是如果我們發現一個用戶比其他數千個用戶的總流量更多，從而影響了其他冲鴨加速用戶的服務質量，我們可能聯繫該用戶以獲取解釋。",
    policy42: "摘要",
    policy43:
      "我們收集最少的使用情況統計信息以維持我們的服務質量。例如，我們可能知道我們的某個冲鴨加速賬號可以訪問我們在紐約，香港和日本的冲鴨加速服務器，並且當月消耗了5GB的數據。由於這個冲鴨加速賬號的使用方式與數千名也可以訪問相同冲鴨加速服務器的冲鴨加速客戶重疊，因此無法將此冲鴨加速賬號唯一識別為對任何特定行為負責。",
    policy44:
      "我們對系統進行了設計以明確消除敏感數據的存儲。我們不知道此冲鴨加速賬號的使用者的實際身份，也不知道他們如何使用我們的服務。我們不擁有任何與用戶在線活動有關的數據，從而堅定了對客戶隱私的堅定承諾。",
    policy45: "匿名的應用程序診斷，包括崩潰報告（可由用戶關閉）",
    policy46:
      "經過您的許可，我們會收集匿名的應用程序診斷數據，其中包括崩潰報告，可用性診斷和冲鴨加速服務器連接診斷。我們在網絡操作工具中使用這些數據來幫助優化網絡速度，並幫助我們確定與特定應用程序，冲鴨加速服務器或ISP相關的問題和需要改進的地方。我們收到的信息是完全匿名的，不能綁定到各個冲鴨加速用戶（即，我們不存儲哪個用戶發送了哪些數據，也不存儲用戶IP地址）。",
    policy47: "同意和年齡限制",
    policy48:
      "使用網站，內容，應用程序，軟件或服務，即表示您同意按照我們的服務條款和隱私權政策中所述處理您的信息。",
    policy49:
      "該服務適用於18歲以上的成年人。如果您認為您的孩子已經向我們提供了信息，請立即告知我們。",
    policy50: "隱私政策的變更",
    policy51:
      "根據適用的隱私法律和原則，我們可能會不時更改隱私政策，恕不另行通知。您繼續使用本網站或服務即表示您接受我們的隱私政策。",
    policy52: "如何联系冲鴨加速",
    policy53:
      "如果您對我們的隱私政策以及我們如何處理您的信息有任何疑問，請隨時通過以下電子郵件地址與冲鴨加速聯繫：dkyhelp@outlook.com",
  },
};
export default privacyPolicyLanguages;
