import { createStore } from "vuex";
import languages from "../comment/languages";
import privacyPolicyLanguages from "../comment/languages_policy";
import termsOfServiceLanguages from "../comment/languages_service";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      lang: "zh",
      languages,
      privacyPolicyLanguages,
      termsOfServiceLanguages,
      env: window.env,
    };
  },
  mutations: {
    change(state, currentLang) {
      state.lang = currentLang;
    },
  },
});
export default store;
