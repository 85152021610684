import axios from "axios";
const _attrName = "div-customer";
const _attrValue = "customer-service";

function getScriptByString(htmlString) {
  let scriptPool = [];
  const reg = /<script.*?>(.|[\r\n])*?<\/script>/g;
  htmlString.match(reg).forEach((e) => {
    scriptPool.push(e);
  });
  return scriptPool;
}

function insertLastScriptNode(script) {
  let scriptDiv = document.createElement("div");
  scriptDiv.innerHTML = script;
  scriptDiv.setAttribute(_attrName, "customer-service");
  document.body.insertBefore(scriptDiv, document.body.lastChild);
}

function getLastScriptNode() {
  let scripts = document.getElementsByTagName("script");
  let r = null;
  Array.from(scripts).forEach((s) => {
    if (s.parentNode.getAttribute(_attrName) === _attrValue) r = s;
  });
  return r || scripts[scripts.length - 1];
}

function setAttributeByScript(element, scriptElement) {
  if (!element) return;
  for (let i = 0; i < element.attributes.length; i++) {
    if (element.attributes[i].specified) {
      scriptElement.setAttribute(
        element.attributes[i].name,
        element.attributes[i].value
      );
    }
  }
}

// 滑动背景色
let touchPosition = window.screen.height / 5;
let scroll = 0;
const utils = {
  parseFuncs() {
    var clickElement = document.getElementById("clickElement");
    // var inproc = false
    var funcs = [];
    for (var i = 0; i < clickElement.childNodes.length; i++) {
      var node = clickElement.childNodes[i];
      var script = node.textContent;
      var m = /function\s+(\w+)\s*\(/.exec(script);
      if (!m) continue;
      funcs.push(m[1]);
    }
    return funcs;
  },
  initClickFunctions() {
    let funcs = utils.parseFuncs();
    let btns = document.querySelectorAll(".download-button");
    btns.forEach((btn) => {
      var url = btn.href;
      let fn = function () {
        if (!funcs.includes("install")) return true;
        return window["install"](url, btn.className.split(" "));
      };
      if (
        /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
      ) {
        btn.addEventListener("touchend", fn);
      } else {
        btn.onclick = fn;
      }
    });
  },
  sbc(dom, topColor, bottomColor) {
    dom.style.backgroundColor =
      (document.documentElement.scrollTop || document.body.scrollTop) <=
      touchPosition
        ? topColor
        : bottomColor;
  },
  bindBGCEvent(dom, topColor, bottomColor) {
    utils.sbc(dom, topColor, bottomColor);
    window.onscroll = function () {
      let tem = document.documentElement.scrollTop || document.body.scrollTop;
      dom.style.backgroundColor = tem - scroll <= 0 ? topColor : bottomColor;
    };
    dom.addEventListener("touchstart", function () {
      utils.sbc(dom, topColor, bottomColor);
    });
  },
  setCookie(cname, cvalue, exdays = 360) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  },
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1);
      if (c.indexOf(name) != -1) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  clearCookie(cname) {
    var d = new Date();
    d.setTime(-1);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=''; " + expires;
  },
  download(href) {
    const a = document.createElement("a");
    a.setAttribute("href", href);
    a.click();
  },
  copy(value) {
    var currentFocus = document.activeElement;

    var input = document.createElement("input");
    document.body.appendChild(input);
    input.style.opacity = 0;
    input.value = value;
    var scrollY = window.scrollY;

    input.focus();
    input.setSelectionRange(0, input.value.length);

    var res = document.execCommand("copy", true);

    currentFocus.focus();
    document.body.removeChild(input);

    window.scrollTo(0, scrollY);

    return res;
  },

  getQuery(variable) {
    let query = window.location.search.substring(1);
    let pars = query.split("&");
    for (let i = 0; i < pars.length; i++) {
      let pair = pars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  },

  creatCutomerService(vueapp) {
    vueapp.$http
      .get(`${process.env.VUE_APP_HOST_OC}/api/website/vue/customer-service`)
      .then((response) => {
        let script = response.data["obj"]["customer-service"].toString();
        let pool = getScriptByString(script);
        pool.forEach((item) => {
          insertLastScriptNode(item);
          let lastScriptNode = getLastScriptNode();
          let scriptElement = document.createElement("script");
          setAttributeByScript(lastScriptNode, scriptElement);
          document.body.insertBefore(scriptElement, document.body.firstChild);
          eval(lastScriptNode.innerText);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  },

  bindSwiperEvent(dom, leftCallBack, rightCallback) {
    let isMove = false;
    let startX = 0;
    let distanceX = 0;
    dom.addEventListener("touchstart", function (e) {
      startX = e.touches[0].clientX;
    });

    dom.addEventListener("touchmove", function (e) {
      isMove = true;
      let moveX = e.touches[0].clientX;
      distanceX = moveX - startX;
    });

    dom.addEventListener("touchend", function (e) {
      if (isMove && Math.abs(distanceX) >= 3) {
        if (distanceX > 0) {
          rightCallback && rightCallback.call(this, e);
        } else {
          leftCallBack && leftCallBack.call(this, e);
        }
      }
    });
  },
  // 对邮箱的验证
  verificationEmail(email) {
    let emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    return emailReg.test(email);
  },
  dateFormat(fmt, date) {
    if (typeof date === "string") date = new Date(date);
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  },
  getOSName() {
    let isAndroid =
      window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
    let isIos = window.navigator.userAgent.toLowerCase().indexOf("iphone") > -1;
    let isIpad =
      /(iPad|iPod|iOS)/i.test(navigator.userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    let isWindows = /windows|win32/i.test(navigator.userAgent);
    if (isAndroid) return "android";
    if (isIos) return "ios";
    if (isIpad) return "ios";
    if (isMac) return "macos";
    if (isWindows) return "windows";
    return "other";
  },
  genGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  },
  sendStats(type, path) {
    let user_id = window.localStorage.getItem("DKY_USER_ID");
    if (!user_id) {
      // 创建user id
      let temp_user_id = this.genGuid();
      window.localStorage.setItem("DKY_USER_ID", temp_user_id);
      user_id = temp_user_id;
    }
    try {
      axios
        .post(`${process.env.VUE_APP_HOST_TDC}/api/${path}/${type}`, {
          user_id: user_id,
          channel: window.env.channel,
        })
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  },
  statsDownload() {
    this.sendStats(this.getOSName(), "incuc");
  },
  statsOpen() {
    this.sendStats(this.getOSName(), "incpv");
  },
};
export default utils;
