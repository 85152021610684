var termsOfServiceLanguages = {
  en: {
    terms0: "Terms of Service",
    terms1:
      "This policy outlines the DuckyVPN terms and conditions of usage. By signing up with and/or utilizing any DuckyVPN service, you agree to be bound by the following terms of this document and the terms of the other named policies included herein by reference and to keep updated on changes as these policies may change at any time.",
    terms2:
      "Terms of Service specific to the DuckyVPN service and application are provided for below.",
    terms3: "Reasonable Use Policy:",
    terms4:
      "DuckyVPN desires all members reasonably use its service. To that end, any customer who puts unreasonable demands upon the service may be curtailed in order to ensure the best possible service for all members.",
    terms5:
      "If a member appears to be unable to maintain a reasonable use of the service, or engages in any Prohibited Activities provided for below, DuckyVPN may cancel, suspend or decline to renew service for that member without notice. A termination fee may also be assessed. It is within DuckyVPN 's sole discretion to determine what comprises unreasonable use.",
    terms6: "Individual DuckyVPN Accounts:",
    terms7:
      "DuckyVPN accounts are not dedicated connections and are for single user, individual use only. Any non-individual use, or any use that is indistinguishable from non-individual use, is prohibited. For example, the following uses are prohibited under this section:",
    terms8: "Sharing a login between people",
    terms9:
      "Use of more simultaneous connections than allowed under your subscribed DuckyVPN account type",
    terms10: "Prohibited Activities:",
    terms11:
      "DuckyVPN is a zero log VPN Service. Golden Frog does not record or retain any user data when you use the DuckyVPN Service.",
    terms12:
      "DuckyVPN  prohibits the use of any of its services in any of the following ways:",
    terms13: "Spamming (e-mail, Usenet, message boards, etc.)",
    terms14: "Copyright, trademark, and patent infringement.",
    terms15:
      "Defamatory or abusive language that advocates bigotry, religious, racial or ethnic hatred.",
    terms16:
      "Publishing or sharing materials that are unlawfully pornographic or indecent",
    terms17: "IP Spoofing",
    terms18: "Illegal or unauthorized access to other computers or networks",
    terms19:
      "Distribution of Internet viruses, worms or other destructive activities",
    terms20: "Export control violations",
    terms21: "All other illegal activities",
    terms22:
      "Prohibited use of the DuckyVPN service can result in an immediate termination of your services.",
    terms23: "Copyright Infringement and Illegal Content",
    terms24:
      "DuckyVPN cannot and does not review the information flowing through its services. You are responsible for determining the legal status of anything you use or copy through any DuckyVPN service.",
    terms25: "Disclaimer of Warranties",
    terms26:
      "Reasonable efforts are taken to improve the accuracy and integrity of the DuckyVPN Services, but we are not responsible for downtime, loss of data, corrupt data, service delay, mistakes, out-of-date information, or other errors. Notwithstanding any other provision of these Terms, we reserve the right to change, suspend, remove, or disable access to the DuckyVPN Services, or any functionality comprising a part of the DuckyVPN Services at any time without notice. In no event will we be liable for making these changes. As a registered user in good standing, you may be provided with limited service by DuckyVPN. We do not warrant, and will not have any liability or responsibility for your use of DuckyVPN Services or other the products or services we provide. We may also impose limits on the use of or access to the DuckyVPN Services, for any reason and without notice or liability. Our website, products and services may be unavailable from time to time due to human, digital, mechanical, telecommunication, software, and other failures. We cannot predict or control when such downtime may occur and cannot control the duration of such downtime.",
    terms27:
      "We shall not be responsible or have any liability for any delay or failure to perform to the extent due to unforeseen circumstances or causes beyond its reasonable control, including, without limitation, failures of your telecommunication or internet service providers, Force Majeure, earthquakes, fires, floods, embargoes, labor disputes and strikes, riots, war, novelty of product manufacture or other unanticipated product development problems, and acts of civil and military authorities.",
    terms28: "Choice of Law",
    terms29:
      "This Agreement shall be governed by and construed in accordance with the laws of the British Virgin Islands, excluding its rules governing conflicts of law.",
    terms30: "Refund Policy:",
    terms31:
      "No refunds will be considered for accounts deleted for violation of our Terms of Service.",
    terms32: "Policy Changes:",
    terms33:
      "DuckyVPN reserves the right to revise, supplement or rescind any of our policies or terms of service at any time, without prior notice. It is the member's sole responsibility to remain informed of our current Terms of Service including all other named policies included by reference therein.",
    terms34: "Acceptance of Terms:",
    terms35:
      "By logging in to any DuckyVPN service, you agree to the DuckyVPN Terms of Service including all other policies incorporated by reference.",
    terms36: "How to Contact US",
    terms37:
      "If you have any questions regarding our Terms of Service and how we handle your information, please feel free to contact us at the following email address: dkyhelp@outlook.com",
  },
  zh: {
    terms0: "服务条款",
    terms1:
      "本政策概述冲鸭加速服务的使用条款和条件。通过登记或使用冲鸭加速服务，您同意遵守以下文件的条款和其它通过参考而被列入其中的条款。您也同意这些政策将在任何时候被改变。",
    terms2: "冲鸭加速服务和应用的具体服务条款如下：",
    terms3: "合理的使用政策",
    terms4:
      "冲鸭加速 希望所有成员都能合理地使用其服务。为此，任何对服务提出无理要求的客户都可能被限制，以确保所有成员都能得到最好的服务。",
    terms5:
      "如果会员表现为无法维持一个服务的合理使用，或从事下列任何限制活动，冲鸭加速可能取消、暂停或拒绝更新服务，不另行通知。终止费也可以估值。冲鸭加速会全权处理哪些属于不合理使用的行为。",
    terms6: "个人冲鸭加速用户",
    terms7:
      "冲鸭加速帐户不是专用的连接，并且只供个人用户，单人使用而已。任何非个人使用，或任何与非个人使用没有区别的事项将被禁止。比如说，以下的用途将在此条款下被禁止：",
    terms8: "与人分享同一个登录",
    terms9: "使用超过您购买的冲鸭加速帐号类型所允许的同时连接。",
    terms10: "被禁止的活动",
    terms11:
      "冲鸭加速是一项零日志VPN服务。您使用冲鸭加速服务时，冲鸭加速不会记录或保留任何用户数据。",
    terms12: "冲鸭加速禁止以以下任何方式使用其任何服务：",
    terms13: "垃圾邮件（电邮、新闻组、留言板等）",
    terms14: "版权，商标和专利侵权。",
    terms15: "主张偏执、宗教、种族或民族仇恨的诽谤或侮辱性语言。",
    terms16: "发布和共享非法色情或淫秽材料",
    terms17: "IP欺骗",
    terms18: "非法或未经授权下登陆到其它电脑或网络",
    terms19: "互联网病毒、蠕虫或其它破坏性活动的分布",
    terms20: "违反出口控制",
    terms21: "所有其它非法活动",
    terms22: "违禁使用冲鸭加速服务可能导致您的服务被立即终止。",
    terms23: "版权侵犯与违法内容",
    terms24:
      "冲鸭加速不能也不会审查通过其服务流动的信息。您有责任确定您使用或通过任何冲鸭加速服务复制的任何内容的法律状态。",
    terms25: "免责声明",
    terms26:
      "我们将努力防止网站和服务受到干扰。但是，这些内容是按“原样”和“可用”的基础提供的，我们不保证（明示或暗示）通过本网站或服务提供的任何材料或信息的准确性，或它们对以下内容的适用性：任何特定目的。我们明确否认任何形式的担保，无论是明示的还是暗示的，包括但不限于适销性或针对特定目的的适用性或非侵权的担保。我们不保证本服务将满足您的要求，也不保证其不间断，及时，安全或无错误，也不对任何缺陷进行纠正。您承认自己承担风险和酌情权访问网站和服务。",
    terms27:
      "冲鸭加速 保留调查我们认为违反这些条款的问题的权利。我们可能会（但无义务）在不另行通知的情况下，自行决定，以任何方式删除，阻止，过滤或限制我们认为是实际或潜在违反本条款所规定限制的任何材料或信息，以及任何其他可能使 冲鸭加速 或我们的客户承担责任的活动。冲鸭加速 对我们未能防止此类材料或信息通过“服务”和/或进入您的计算设备的任何故障不承担任何责任。",
    terms28: "适用法律",
    terms29: "本协议应受塞舌尔法律的管辖并根据其解释，但其法律冲突规则除外。",
    terms30: "退款政策",
    terms31: "对于因违反我们的服务条款而被删除的帐户; 我们不会考虑退款。",
    terms32: "政策改变",
    terms33:
      "冲鸭加速在任何时候没有事先通告下可保留修订、补充或撤销任何我们政策或服务条款的权利。成员本身有绝对的责任去了解我们目前的服务条款，包括通过参考途径被列入其中的政策。",
    terms34: "接受的条款",
    terms35:
      "通过登录到任何冲鸭加速服务，您同意冲鸭加速的服务条款，包括其它援引作为参考的政策。",
    terms36: "联系我们",
    terms37:
      "如果您对我们的隐私政策以及我们如何处理您的信息有任何疑问，请随时通过以下电子邮件地址与我们联系：dkyhelp@outlook.com",
  },
  tw: {
    terms0: "服務條款",
    terms1:
      "本政策概述冲鴨加速服務的使用條款和條件。通過登記或使用冲鴨加速服務，您同意遵守以下文件的條款和其它通過參考而被列入其中的條款。您也同意這些政策將在任何時候被改變。",
    terms2: "冲鴨加速服務和應用的具體服務條款如下：",
    terms3: "合理的使用政策",
    terms4:
      "冲鴨加速 希望所有成員都能合理地使用其服務。為此，任何對服務提出無理要求的客戶都可能被限制，以確保所有成員都能得到最好的服務。",
    terms5:
      "如果會員表現為無法維持一個服務的合理使用，或從事下列任何限制活動，冲鴨加速可能取消、暫停或拒絕更新服務，不另行通知。終止費也可以估值。冲鴨加速會全權處理哪些屬於不合理使用的行為。",
    terms6: "個人冲鴨加速用戶",
    terms7:
      "冲鴨加速帳戶不是專用的連接，並且只供個人用戶，單人使用而已。任何非個人使用，或任何與非個人使用沒有區別的事項將被禁止。比如說，以下的用途將在此條款下被禁止：",
    terms8: "與人分享同一個登錄",
    terms9: "使用超過您購買的冲鴨加速帳號類型所允許的同時連接。",
    terms10: "被禁止的活動",
    terms11:
      "冲鴨加速是一項零日誌VPN服務。您使用冲鴨加速服務時，冲鴨加速不會記錄或保留任何用戶數據。",
    terms12: "冲鴨加速禁止以以下任何方式使用其任何服務：",
    terms13: "垃圾郵件（電郵、新聞組、留言板等）",
    terms14: "版權，商標和專利侵權。",
    terms15: "主張偏執、宗教、種族或民族仇恨的誹謗或侮辱性語言。",
    terms16: "發布和共享非法色情或淫穢材料",
    terms17: "IP欺騙",
    terms18: "非法或未經授權下登陸到其它電腦或網絡",
    terms19: "互聯網病毒、蠕蟲或其它破壞性活動的分佈",
    terms20: "違反出口控制",
    terms21: "所有其它非法活動",
    terms22: "違禁使用冲鴨加速服務可能導致您的服務被立即終止。",
    terms23: "版權侵犯與違法內容",
    terms24:
      "冲鴨加速不能也不會審查通過其服務流動的信息。您有責任確定您使用或通過任何冲鴨加速服務複製的任何內容的法律狀態。",
    terms25: "免責聲明",
    terms26:
      "我們將努力防止網站和服務受到干擾。但是，這些內容是按“原樣”和“可用”的基礎提供的，我們不保證（明示或暗示）通過本網站或服務提供的任何材料或信息的準確性，或它們對以下內容的適用性：任何特定目的。我們明確否認任何形式的擔保，無論是明示的還是暗示的，包括但不限於適銷性或針對特定目的的適用性或非侵權的擔保。我們不保證本服務將滿足您的要求，也不保證其不間斷，及時，安全或無錯誤，也不對任何缺陷進行糾正。您承認自己承擔風險和酌情權訪問網站和服務。",
    terms27:
      "冲鴨加速 保留調查我們認為違反這些條款的問題的權利。我們可能會（但無義務）在不另行通知的情況下，自行決定，以任何方式刪除，阻止，過濾或限制我們認為是實際或潛在違反本條款所規定限制的任何材料或信息，以及任何其他可能使冲鴨加速 或我們的客戶承擔責任的活動。冲鴨加速 對我們未能防止此類材料或信息通過“服務”和/或進入您的計算設備的任何故障不承擔任何責任。",
    terms28: "適用法律",
    terms29: "本協議應受塞舌爾法律的管轄並根據其解釋，但其法律衝突規則除外。",
    terms30: "退款政策",
    terms31: "對於因違反我們的服務條款而被刪除的帳戶; 我們不會考慮退款。",
    terms32: "政策改變",
    terms33:
      "冲鴨加速在任何時候沒有事先通告下可保留修訂、補充或撤銷任何我們政策或服務條款的權利。成員本身有絕對的責任去了解我們目前的服務條款，包括通過參考途徑被列入其中的政策。",
    terms34: "接受的條款",
    terms35:
      "通過登錄到任何冲鴨加速服務，您同意冲鴨加速的服務條款，包括其它援引作為參考的政策。",
    terms36: "聯繫我們",
    terms37:
      "如果您對我們的隱私政策以及我們如何處理您的信息有任何疑問，請隨時通過以下電子郵件地址與我們聯繫：dkyhelp@outlook.com",
  },
};
export default termsOfServiceLanguages;
