<template>
  <div class="flex download-parent download-margin">
    <a class="flex download-button download-android" @click="downloadInstall()" :href="`${apkUrl}`">
<!--      <div class="download-android-icon"></div>-->
<!--      <span class="langlist" langname="download-android">Android 下载</span>-->
    </a>
  </div>
</template>

<script>
  import utils from '../utils/utils'
  export default {
    name: 'Download',
    components: {},
    setup () {
      let env = window.env
      let apkUrl = `/apks/${env.version}/${env.app}-${env.version}-release-${env.channel}.apk`
      function downloadInstall () {
        utils.statsDownload()
      }
      return {
        downloadInstall,
        apkUrl,
        env
      }
    }
  }
</script>

<style scoped>

.download-parent {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.download-parent>a{
  padding-bottom: 1.3rem;
  cursor: pointer;
  width: 77%;
  height: 3.5rem;
  max-width: 20rem;
  justify-content: center;
  background: url("~@/assets/images/android_download.png") no-repeat center center;
  background-size: 100% 100%;
  align-items: center;
}
.download-parent>a>span{
  margin-top: 2px;
  margin-left: .7rem;
  font-size: 1.2rem;
  color: #e18e40;
}
.download-parent>a>.download-android-icon{
  width: 1.5rem;
  height: 1.5rem;
  background-color: #e18e40;
  -webkit-mask: url("~@/assets/images/Android@2x.png") no-repeat;
  mask: url("~@/assets/images/Android@2x.png") no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%
}

.download-parent>a>img{
  width: 1.5rem;
  height: 1.5rem;
  background-blend-mode: color-burn;
}
.download-margin {
  margin: 4rem 0 2rem 0;
}


</style>
