import axios from 'axios'
function getHashNumber(str,size){
  var hashCode=0
  for(var i=0;i<str.length;i++){
    hashCode=37* hashCode + str.charCodeAt(i) //获取编码
  }
  var index=hashCode%size
  return index
}
function commalize (s) {
  return s.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function subNumber (value) {
  return Number(value.toString().substring(3,9))
}

function getOSName(){
  let isAndroid = window.navigator.userAgent.toLowerCase().indexOf('android') > -1;
  let isIos = (window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1);
  let isIpad = /(iPad|iPod|iOS)/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  let isMac =  /macintosh|mac os x/i.test(navigator.userAgent);
  let isWindows = /windows|win32/i.test(navigator.userAgent);
  if (isAndroid) return 'android'
  if (isIos) return 'ios'
  if (isIpad) return 'ios'
  if (isMac) return 'macos'
  if (isWindows) return 'windows'
  return 'other'
}
function genGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r&0x3 | 0x8);
    return v.toString(16);
  });
}
function sendStats(type, path){
  let user_id = window.localStorage.getItem('WUM_USER_ID')
  if (!user_id) {
    // 创建user id
    let temp_user_id = genGuid()
    window.localStorage.setItem('WUM_USER_ID', temp_user_id)
    user_id = temp_user_id
  }
  try {
    axios.post(`${process.env.VUE_APP_HOST_TDC}/api/${path}/${type}`, {"user_id": user_id,"channel":window.env.channel}).then(() => {
    }).catch(() => {
    })
  } catch (_e) {
    console.log(_e)}
}
function statsDownload () {
  sendStats(this.getOSName(),'incuc')
}
function statsOpen () {
  sendStats(this.getOSName(), 'incpv')
}

export  default { getHashNumber,commalize, subNumber, getOSName, statsDownload, statsOpen  }
