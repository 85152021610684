<template>
  <div>
    <div class="duckyPNG flex">
      <div class="flex">
        <img src="@/assets/images/duckyAPP.png" alt="duckyVPN">
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'BannerChongya',
    components: {
    },
    setup () {
      return {
      }
    },
    created () {
    }

  }
</script>

<style scoped>

</style>
